@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  /* BACKGROUNDS */
  .bg-default {
    @apply bg-base-white dark:bg-zinc-950;
  }
  .bg-default--hover {
    @apply bg-slate-50 dark:bg-zinc-900;
  }
  .bg-secondary {
    @apply bg-slate-100 dark:bg-slate-900;
  }
  .bg-primary {
    @apply bg-orange-500 hover:bg-orange-400;
  }
  .bg-primary--hover {
    @apply bg-orange-50 hover:bg-orange-900;
  }

  /* FOREGROUNDS */
  .fg-strong {
    @apply text-slate-900 dark:text-base-white fill-slate-900 dark:fill-base-white stroke-slate-900 dark:stroke-base-white;
  }
  .fg-default {
    @apply text-slate-700 dark:text-zinc-200 fill-slate-700 dark:fill-zinc-200 stroke-slate-700 dark:stroke-zinc-200;
  }
  .fg-subtle {
    @apply text-slate-500 dark:text-zinc-500 fill-slate-500 dark:fill-zinc-500 stroke-slate-500 dark:stroke-zinc-500;
  }
  .fg-weak {
    @apply text-slate-400 dark:text-zinc-600 fill-slate-400 dark:fill-zinc-600 stroke-slate-400 dark:stroke-zinc-600;
  }
  .fg-weaker {
    @apply text-slate-300 dark:text-zinc-700 fill-slate-300 dark:fill-zinc-700 stroke-slate-300 dark:stroke-zinc-700;
  }
  .fg-primary {
    @apply text-orange-500 dark:text-orange-400 fill-orange-500 dark:fill-orange-400 stroke-orange-500 dark:stroke-orange-400;
  }
  .fg-primary-subtle {
    @apply text-orange-400 dark:text-orange-600 fill-orange-400 dark:fill-orange-600 stroke-orange-400 dark:stroke-orange-600;
  }
  .fg-primary-weak {
    @apply text-orange-300 dark:text-orange-700 fill-orange-300 dark:fill-orange-700 stroke-orange-300 dark:stroke-orange-700;
  }
  .fg-primary--hover {
    @apply text-orange-600 dark:text-orange-500 fill-orange-600 dark:fill-orange-500 stroke-orange-600 dark:stroke-orange-500;
  }
  .fg-primary--disabled {
    @apply text-orange-300 dark:text-orange-700 fill-orange-300 dark:fill-orange-700 stroke-orange-300 dark:stroke-orange-700;
  }

  /* BORDERS */
  .border-default {
    @apply border-slate-300 dark:border-zinc-700 stroke-slate-300 dark:stroke-zinc-700;
  }
  .border-subtle {
    @apply border-slate-200 dark:border-zinc-800 stroke-slate-200 dark:stroke-zinc-800;
  }
  .border-weak {
    @apply border-slate-100 dark:border-zinc-900 stroke-slate-100 dark:stroke-zinc-900;
  }
  .border-primary {
    @apply border-orange-500 dark:border-orange-400 stroke-orange-500 dark:stroke-orange-400;
  }
}
