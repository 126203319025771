@tailwind base;
@tailwind components;
@tailwind utilities;

*,
html,
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

input[type='color'] {
  min-height: 38px;
  margin-top: 4px;
  block-size: 38px;
  background-color: white;
}

input[type='color']::-webkit-color-swatch,
input[type='color']::-webkit-color-swatch-wrapper {
  border: none;
  border-radius: 4px;
  padding: 0;
}

.non-selectable {
  pointer-events: none;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
}

fieldset,
img {
  border: 0;
}

#dropdown-tag-type,
#dropdown-integrations {
  max-height: 34px;
  min-height: 34px;
  @apply bg-base-white py-1 px-3 gap-2 border-[1px] flex-1 border-slate-200 rounded-2 flex items-center;
}

#dropdown-tag-type.ui.dropdown .menu .item,
#dropdown-integrations.ui.dropdown .menu .item {
  cursor: pointer;
  overflow: hidden;
  color: theme(colors.slate.500);
  leading-trim: both;
  text-edge: cap;
  text-overflow: ellipsis;
  font-size: theme(fontSize.50);
  font-style: normal;
  font-weight: theme(fontWeight.normal);
  line-height: 14px; /* 100% */
}

#dropdown-tag-type > div.divider.text,
#dropdown-integrations > div.divider.text {
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-feature-settings: normal;
  font-kerning: auto;
  font-optical-sizing: auto;
  font-size: 14px;
  font-stretch: 100%;
  font-style: normal;
  font-variant-alternates: normal;
  font-variant-caps: normal;
  font-variant-east-asian: normal;
  font-variant-ligatures: normal;
  font-variant-numeric: normal;
  font-variant-position: normal;
  font-variation-settings: normal;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 14px;
  tab-size: 4;
  text-align: start;
  text-indent: 0px;
  text-shadow: none;
  text-size-adjust: 100%;
  text-transform: none;
  word-spacing: 0px;
  color: theme(colors.slate.600);
}

.flex-container {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.flex-container .header {
  flex: 0 1 auto;
}

.flex-container .content {
  flex: 1 1 auto;
}

.flex-container .footer {
  flex: 0 1 auto;
}

address,
caption,
cite,
code,
dfn,
em,
strong,
th,
var {
  font-style: normal;
  font-weight: normal;
}

ol,
ul,
li {
  list-style: none;
}

caption,
th {
  text-align: left;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}

div,
img,
input,
textarea,
select {
  outline: none;
}

.ui.form textarea {
  resize: none;
}

input:focus-visible {
  outline: none !important;
}

strong {
  font-weight: bold;
}

.ui.form .disabled.field,
.ui.form .disabled.fields .field,
.ui.form .field:disabled,
.ui.form .field:disabled:not(.secondary-button-eased-label-disabled),
.ui.form .field.disabled > label,
.ui.form .fields.disabled > label {
  opacity: 1 !important;
  color: rgba(0, 0, 0) !important;
}

#settings-surveyrating .ui.table td {
  padding: 11px 2px 11px 2px !important;
}

.react-multi-email [data-tag] [data-tag-item] {
  line-height: 1.5;
}

label {
  display: block;
  /* margin: 0 0 0.28571429rem 0; */
  color: rgba(0, 0, 0, 0.87) !important;
  font-size: 0.92857143em;
  font-weight: 700;
  text-transform: none;
}

em {
  font-style: italic;
}

a img {
  border: none;
}

*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.apexcharts-canvas .apexcharts-reset-zoom-icon.apexcharts-selected svg,
.apexcharts-canvas .apexcharts-selection-icon.apexcharts-selected svg,
.apexcharts-canvas .apexcharts-zoom-icon.apexcharts-selected svg {
  fill: var(--agitalks) !important;
}

.apexcharts-pan-icon.apexcharts-selected svg {
  stroke: var(--agitalks) !important;
}

.word-wrap-1 {
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.word-wrap-2 {
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.apexcharts-menu.apexcharts-menu-open {
  min-width: 75px !important;
}

.dashboard-filter {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(235px, 1fr));
}

.ui.dropdown:not(#dropdown-relation-users, #dropdown-relation-teams, #dropdown-relation-customers, #dropdown-relation-contacts, #dropdown-tag-type, #dropdown-integrations) {
  display: inline-table !important;
}

.ui.dropdown:not(#header-batch-container, .visible, #headerchat, #dropdown-tag-type, #dropdown-integrations, .custom-dropdown) {
  max-height: 200px !important;
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 38.5px !important;
}
.ui.dropdown.visible:not(#header-batch-container, #headerchat, #dropdown-tag-type, #dropdown-integrations, .custom-dropdown) {
  min-height: 38.5px;
}

.dashboard-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(460px, 1fr));
  grid-gap: 12px;
}

*::-webkit-scrollbar {
  width: 10px !important;
  height: 6px !important;
}

#release-css-29-05 {
  display: flex;
}

#sidemenu::-webkit-scrollbar {
  width: 6px !important;
}

*::-webkit-scrollbar-track {
  background-color: var(--background) !important;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--gray-bbb) !important;
}

.historySelector::-webkit-scrollbar-track {
  border-radius: 100%;
}

#sidemenu::-webkit-scrollbar-track,
.widget::-webkit-scrollbar-track,
#modal-scroll::-webkit-scrollbar-track,
#content-files::-webkit-scrollbar-track,
#modal-relation::-webkit-scrollbar-track {
  background-color: var(--white) !important;
}

#messages-area::-webkit-scrollbar-track {
  background-color: var(--bg-grey-ef) !important;
}

#agidesk-services::-webkit-scrollbar-track {
  @apply !bg-slate-50;
}
#agidesk-team-services::-webkit-scrollbar-track {
  @apply !bg-white;
}

.avatar {
  height: 100% !important;
  width: 100% !important;
  object-fit: contain !important;
  border-radius: 9999px !important;
}

.avatar35 {
  height: 35px !important;
  width: 35px !important;
  object-fit: contain !important;
  border-radius: 9999px !important;
}

.agidesk-logo {
  object-fit: contain !important;
  width: 26.25px !important;
}

input {
  outline: none;
}

.my_file {
  align-self: center;
  position: absolute;
  bottom: -25px;
  outline: none;
  color: transparent;
  width: 100%;
  border: none;
  box-sizing: border-box;
  padding: 10px calc(50% - 10px);
  cursor: pointer;
  transition: 0.5s;
  background: var(--black-alpha80);
  margin-bottom: 20px;
}

.my_file::-webkit-file-upload-button,
.my_file::file-selector-button {
  visibility: hidden;
}

.my_file::before {
  content: '\f030';
  font-family: fontAwesome;
  font-size: 20px;
  color: var(--white);
  display: inline-block;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* --------- FILES ON CHATS --------- */

.c2 {
  grid-template-columns: 50% 50%;
  column-count: 2;
}

.c3,
.c4 {
  grid-template-columns: 50% 50%;
  grid-template-rows: 50% 50%;
  column-count: 2;
}

.c5,
.c6 {
  grid-template-columns: 50% 50%;
  grid-template-rows: 33% 33% 33%;
  column-count: 2;
}

.c7,
.c8 {
  grid-template-columns: 50% 50%;
  grid-template-rows: 25% 25% 25% 25%;
  column-count: 2;
}

.c9,
.c10 {
  grid-template-columns: 50% 50%;
  grid-template-rows: 20% 20% 20% 20% 20%;
  column-count: 2;
}

.ring {
  --tw-ring-offset-shadow: none !important;
  --tw-ring-shadow: none !important;
  box-shadow: none !important;
  border: none !important;
}

.outline {
  outline-style: none !important;
}

/* --------- DATERANGE PICKER  --------- */
/*to hide left panel from date range picker */
.rdrInputRanges {
  display: none;
}

.rdrDefinedRangesWrapper {
  width: 175px !important;
}

@media (max-width: 1024px) {
  .rdrDefinedRangesWrapper {
    width: 100% !important;
  }
}

.rdrDateDisplayWrapper {
  background-color: var(--white) !important;
}

.rdrDateDisplayItem {
  box-shadow: none !important;
}

.rdrCalendarWrapper,
.rdrDateRangeWrapper {
  width: 100% !important;
}

.rdrMonth {
  width: 100% !important;
}

/* --------- BUBBLES CHAT --------- */
#bubble {
  width: 100%;
  margin: 10px 0;
}

#bubble .ballon {
  background: var(--white);
  display: table;
  padding: 4px 6px;
  border-radius: 0 8px 8px 8px;
  max-width: 90%;
  /* max-width: 50%; //TRF-385716 */
  box-shadow: 1px 2px var(--gray-ddd);
}

#bubble .note-ballon {
  background: theme(colors.amber.50);
  display: table;
  padding: 4px 6px;
  border-radius: 0 8px 8px 8px;
  max-width: 90%;
  /* max-width: 50%; //TRF-385716 */
  box-shadow: 1px 2px var(--gray-ddd);
}

.note-icon {
  display: inline-flex;
  height: 26px;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  margin-left: 12px;
  flex-shrink: 0;
  border-radius: 999px;
  background: var(--colors-amber-100, #fef3c7);
}
.note-icon > i {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: var(--colors-gray-600, #4b5563);
  font-size: 14px;
}
.note-icon > span {
  color: var(--colors-gray-600, #4b5563);
  /* font-family: Inter; */
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: var(--font-leading-label-on-button-sm, 14px); /* 116.667% */
}

#bubble .ballon-preview {
  background: var(--background);
  display: table;
  padding: 4px 6px;
  border-radius: 0 8px 8px 8px;
  box-shadow: 1px 2px var(--gray-ddd);
}

#bubble.not-mine:before {
  content: '';
  position: absolute;
  left: -8px;
  right: auto;
  top: 0;
  border-top: 10px solid var(--white);
  border-left: 10px solid transparent;
  border-left: auto;
}

#bubble.mine {
  justify-content: flex-end;
}

#bubble.mine .ballon:after {
  content: '';
  position: absolute;
  right: -10px;
  left: auto;
  top: 0;
  border-top: 10px solid var(--green-ballon);
  border-right: 10px solid transparent;
  border-right: auto;
}

#bubble.mine .ballon {
  background: var(--green-ballon);
  display: table;
  padding: 4px 6px;
  border-radius: 8px 0 8px 8px;
  /* max-width: 50%; //TRF-385716  */
  max-width: 90%;
  box-shadow: -1px 2px var(--gray-ddd);
}

#bubble.mine .ballon:after {
  content: '';
  position: absolute;
  right: -8px;
  left: auto;
  top: 0;
  border-top: 10px solid var(--green-ballon);
  border-right: 10px solid transparent;
  border-right: auto;
}
#bubble.mine .note-ballon:after {
  content: '';
  position: absolute;
  right: -10px;
  left: auto;
  top: 0;
  border-top: 10px solid theme(colors.amber.50);
  border-right: 10px solid transparent;
  border-right: auto;
}

#bubble.mine .note-ballon {
  background: theme(colors.amber.50);
  display: table;
  padding: 4px 6px;
  border-radius: 8px 0 8px 8px;
  /* max-width: 50%; //TRF-385716  */
  max-width: 90%;
  box-shadow: -1px 2px var(--gray-ddd);
}

#bubble.mine .note-ballon:after {
  content: '';
  position: absolute;
  right: -8px;
  left: auto;
  top: 0;
  border-top: 10px solid theme(colors.amber.50);
  border-right: 10px solid transparent;
  border-right: auto;
}

/* --------- EMOJI PICKER --------- */

aside.emoji-picker-react {
  width: auto !important;
  background: var(--white) !important;
}

.emoji-picker-react .emoji-group:before {
  background: var(--white) !important;
}

/* --------- HOVER ON CARDS --------- */

.container-hover {
  position: relative;
  background-color: var(--agitalks);
}

.image {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.container-hover:hover .image {
  opacity: 0.05;
}

.container-hover:hover .middle {
  opacity: 1;
}

.icon-text-hover {
  color: var(--white);
}

.ui.toggle.checkbox input:checked ~ .box:before,
.ui.toggle.checkbox input:checked ~ label:before {
  background-color: var(--orange-500) !important;
}
.ui.toggle.checkbox:hover input:checked ~ .box:before,
.ui.toggle.checkbox:hover input:checked ~ label:before {
  background-color: var(--orange-600) !important;
}
.ui.toggle.checkbox input ~ .box:before,
.ui.toggle.checkbox input ~ label:before {
  background-color: var(--slate-400) !important;
}
.ui.toggle.checkbox:hover input ~ .box:before,
.ui.toggle.checkbox:hover input ~ label:before {
  background-color: var(--slate-500) !important;
}

.ui.card {
  margin: 0 0 !important;
}

#headerchat.menu.transition.left.visible,
#groupchat.menu.transition.left.visible {
  margin-right: 8px;
}

#externalapp.ui.active.visible.dropdown .visible.menu.transition {
  width: 138px;
  margin-left: -16.5px;
}

#custom-file {
  font-size: 12px;
}

a.message-link {
  color: #4183c4 !important;
  text-decoration: none !important;
}

.break-break {
  white-space: break-spaces;
  word-break: break-word;
}

/* Dropdown margin treatment */
/* .pusher .ui.selection.dropdown .menu {
  min-width: calc(100% + 3px) !important;
  width: calc(100% + 3px) !important;
} */

.ui.selection.dropdown#dropdown-tag-type {
  min-width: 10rem;
}

.ui.selection.dropdown#dropdown-integrations {
  min-width: 18rem;
}

.selected > .ui.selection.dropdown#dropdown-integrations {
  background-color: theme(colors.orange.50);
  border-color: theme(colors.orange.300);
  color: theme(colors.orange.500);
}
.selected > .ui.selection.dropdown#dropdown-externalappnumber,
.selected > .ui.selection.dropdown#dropdown-externalapptype {
  color: theme(colors.orange.500);
}

.selected > .ui.selection.dropdown#dropdown-externalappnumber .text,
.selected > .ui.selection.dropdown#dropdown-externalapptype .text,
.selected > .ui.selection.dropdown#dropdown-integrations .text {
  color: theme(colors.orange.500);
}

/* TO DO */
/* .tox-tbtn.tox-tbtn--select {
 width: 160px;
} */

#modal-relation .ui.selection.dropdown .menu,
.ui.input,
.sidebar .ui.selection.dropdown .menu {
  min-height: 38.5px !important;
  min-width: calc(100% + 2px) !important;
  width: calc(100% + 2px) !important;
}

/* .ui.selection.dropdown .menu {
  min-width: calc(100% + 1px) !important;
  width: calc(100% + 1px) !important;
} */

#form-email-editor .blockbuilder-branding {
  display: none;
}

.tox-tinymce {
  border: 1px solid rgba(34, 36, 38, 0.15) !important;
  border-radius: 4px !important;
}

.tox:not(.tox-tinymce-inline) .tox-editor-header {
  border-bottom: 1px solid rgba(34, 36, 38, 0.15) !important;
  box-shadow: none !important;
}

#form-email-editor iframe {
  min-width: calc(100% + 2px) !important;
}

#survey-body-content .ratingoptions {
  padding: 7px;
}

#survey-body-content .ratingoptions:hover,
.ratingoptions.hover {
  padding: 7px;
  border-radius: 8px;
  background-color: rgba(194, 233, 236, 0.242);
}

.ui.accordion .title:not(.ui) {
  display: flex;
  align-items: center;
}

.react-tel-input .form-control {
  position: relative;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px !important;
  margin-left: 0;
  background: #ffffff;
  border: 1px solid #cacaca;
  border-radius: 5px;
  outline: none;
  height: 38.5px !important;
  width: 100% !important;
}

.react-tel-input .country-list {
  overflow-x: hidden;
  width: inherit !important;
  max-height: 150px !important;
  align-self: stretch;
}

.react-tel-input .flag-dropdown {
  background-color: white !important;
}

/*========= DEPLOY TEST =====*/
.deploytest {
  display: none;
}

#app-externalapp-htmlmessage .externalapplink {
  text-decoration: underline;
  color: #4183c4;
}

/* .ui.dropdown .menu .item.selected {
  background-color: theme(colors.orange.100) !important;
  border: 1px solid theme(colors.orange.400) !important;
  color:  theme(colors.orange.400) !important;
}
.ui.dropdown .menu .item.selected {
  background-color: theme(colors.orange.100) !important;
  border: 1px solid theme(colors.orange.500) !important;
  color:  theme(colors.orange.500) !important;
} */

.ui.selection.dropdown > .ui.label,
.ui.multiple.selection.dropdown > .ui.label {
  background-color: theme(colors.orange.50) !important;
  border: 1px solid theme(colors.orange.400) !important;
  color: theme(colors.orange.400) !important;
  border-radius: 4px;
}
.ui.selection.dropdown > .ui.label:hover,
.ui.multiple.selection.dropdown > .ui.label:hover {
  background-color: theme(colors.orange.100) !important;
  border: 1px solid theme(colors.orange.500) !important;
  color: theme(colors.orange.500) !important;
  border-radius: 4px;
}

.ui.fluid.pointing.secondary.item.menu > .active.item.active {
  color: theme(colors.orange.500);
  border-color: theme(colors.orange.500);
}

/* ============================================================== */
.ui.form .field.disabled > label,
.ui.form .field > label {
  font-weight: 600;
  color: var(--colors-zinc-700, #3f3f46) !important;
}
.ui.form .field {
  margin: 0;
  margin-bottom: 12px;
}

.publicroute-container {
  display: grid;
  grid-template-columns: 66% 34%;
  height: 100vh;
  overflow-y: hidden;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.form-container {
  margin-left: 30px;
  margin-right: 30px;
  width: -webkit-fill-available;
  max-width: 400px;
}

@media (min-width: 1280px) {
  .publicroute-container {
    grid-template-columns: 5/7 2/7;
  }
}

@media (max-width: 1024px) {
  .publicroute-container {
    grid-template-columns: 1fr;
  }

  .image-container {
    display: none;
  }
}

.red-tooltip {
  display: inline-flex;
  height: 31px;
  padding: 0px var(--spacing-4, 16px);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: var(--spacing-25, 10px);
  flex-shrink: 0;
  stroke-width: 1px !important;
  stroke: var(--colors-red-800, #991b1b) !important;
  border: 1px solid var(--colors-red-800, #991b1b) !important;
  color: var(--colors-red-800, #991b1b) !important;
  /* Text/xs-medium */
  /* font-family: var(--font-family-body, Inter); */
  font-size: var(--font-size-text-xs, 12px);
  font-style: normal;
  font-weight: var(--font-weight-medium, 500);
  line-height: var(--font-leading-text-xs, 16px); /* 133.333% */
}

.action-button-show-hide > i {
  display: flex;
  width: 12px;
  height: 12px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  color: var(--colors-orange-500, #f97316);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.action-button-show-hide {
  display: flex;
  width: 38px;
  height: 38px;
  padding: var(--spacing-35, 14px) 13px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: var(--spacing-25, 10px);
  flex-shrink: 0;
  border-radius: 2px;
  border: 1px solid var(--colors-orange-500, #f97316);
}

.action-button-show-hide:disabled > i {
  color: #e2e8f0 !important;
}

.ui.label {
  line-height: initial;
}
.action-button-show-hide:disabled {
  border: 1px solid #e2e8f0 !important;
  background: var(--colors-base-white, #ffffff) !important;
}
.action-button-show-hide.hide:hover > i,
.action-button-show-hide.show > i,
.action-button-show-hide:hover > i {
  color: var(--colors-base-white, #ffffff);
}
.action-button-show-hide.show,
.action-button-show-hide:hover {
  background: var(--colors-orange-500, #f97316);
}
.action-button-show-hide.show:hover {
  border: 1px solid var(--colors-orange-200, #fed7aa);

  background: var(--colors-orange-200, #fed7aa);
}

.ui.input > input:not(.custom-dropdown) {
  display: flex !important;
  height: 38px !important;
  padding: 0px var(--spacing-4, 16px) !important;
  align-items: center !important;
  flex: 1 0 0 !important;
  border-radius: 4px !important;
  border: 1px solid var(--colors-zinc-300, #d4d4d8) !important;
}

.ui.input > input::placeholder:not(.custom-dropdown) {
  color: var(--colors-zinc-500, #71717a) !important;
  /* Text/xs-medium */
  /* font-family: var(--font-family-body, Inter)!important; */
  font-size: var(--font-size-text-xs, 12px) !important;
  font-style: normal !important;
  font-weight: var(--font-weight-medium, 500) !important;
  line-height: var(--font-leading-text-xs, 16px) !important; /* 133.333% */
}

.ui.input > input:disabled:not(.custom-dropdown) {
  border: 1px solid theme(colors.slate.300) !important;
  background: theme(colors.slate.100) !important;
}

.ui.input > input:disabled::placeholder:not(.custom-dropdown) {
  color: theme(colors.slate.400) !important;
}

.ui.input > input:hover:not(.custom-dropdown) {
  border: 1px solid var(--colors-zinc-400, #a1a1aa) !important;
}

.ui.input > input:hover::placeholder:not(.custom-dropdown) {
  color: var(--colors-zinc-800, #27272a) !important;
}

.ui.input > input:focus:not(.custom-dropdown) {
  border: 1px solid var(--colors-blue-300, #93c5fd) !important;
}

.ui.input > input:focus::placeholder:not(.custom-dropdown) {
  color: var(--colors-zinc-700, #3f3f46) !important;
}
.error .ui.input > input:not(.custom-dropdown) {
  background: var(--colors-red-50, #fef2f2) !important;
  border: 1px solid var(--colors-red-300, #fca5a5) !important;
}

.error .ui.input > input::placeholder:not(.custom-dropdown) {
  color: var(--colors-red-400, #f87171) !important;
}

.new-link {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-25, 10px);
  color: var(--agitalks-blue-link, #4183c4);
  text-align: center;
  /* font-family: var(--font-family-body, Lato); */
  font-size: 13px;
  font-style: normal;
  font-weight: var(--font-weight-medium, 500);
  line-height: var(--font-leading-text-xs, 16px); /* 133.333% */
}
.new-link:hover {
  color: var(--agitalks-blue-link--hover, #1e70bf);
}
.new-link:disabled {
  color: var(--agitalks-gray-bbb, #bbb);
}

.secondary-button-eased,
.secondary-button-eased-disabled {
  display: flex;
  width: 88.5px;
  height: 38px;
  padding: theme(spacing.3) theme(spacing.1);
  justify-content: center;
  align-items: center;
  gap: theme(spacing[2.5]);
  flex-shrink: 0;
  border-radius: 2px;
  border: 1px solid theme(colors.orange.200);
}
.secondary-button-eased {
  cursor: pointer;
  border: 1px solid theme(colors.orange.500);
}

.secondary-button-eased:hover,
.secondary-button-eased:hover > button {
  background: var(--colors-orange-500, #f97316);
  color: var(--colors-base-white, #ffffff) !important;
}
.secondary-button-eased-label:hover {
  color: var(--colors-base-white, #ffffff) !important;
}

#action-button:disabled {
  color: theme(colors.orange.200) !important;
}

.secondary-button-eased-label,
.secondary-button-eased-label-disabled {
  overflow: hidden;
  color: theme(colors.orange.200) !important;
  text-overflow: ellipsis;
  /* Label/on-button-sm */
  font-size: var(--font-size-label-on-button-sm, 14px);
  font-style: normal;
  font-weight: var(--font-weight-semibold, 600);
  line-height: var(--font-leading-label-on-button-sm, 14px); /* 100% */
}

a.item,
a.active.item {
  color: #212121;
  text-align: center;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  /* font-family: var(--font-family-body, Inter); */
  font-size: var(--font-size-text-xs, 12px);
  font-style: normal;
  font-weight: 600;
  line-height: var(--font-leading-text-xs, 16px); /* 133.333% */
}

.active.item.custom-tab div[role='combobox'] {
  height: 42px !important;
  max-width: 150px;
}

.custom-tab .ui.dropdown:not(.button) > .default.text {
  max-width: 150px !important;
  color: #212121;
  font-size: var(--font-size-text-xs, 12px);
  font-style: normal;
  font-weight: 600;
  line-height: var(--font-leading-text-xs, 16px); /* 133.333% */
  @apply !truncate;
}

.form-container .ui.secondary.pointing.menu a.item:active {
  border-color: transparent;
}

.form-container .ui.menu .item > p:last-child {
  margin-bottom: 2px !important;
}

a.item.custom-tab {
  padding: 0 !important;
}

.item a.item.custom-tab,
.item a.item.custom-tab .dropdown.icon {
  font-weight: 600;
  color: #212121 !important;
}
.active.item a.item.custom-tab,
.item a.item.active.custom-tab .dropdown.icon {
  font-weight: 600;
  color: theme(colors.orange.500) !important;
}

a.item.custom-tab .ui.dropdown:not(.button) > .text {
  max-width: 150px !important;
  @apply !truncate;
}

.secondary-button-eased-label {
  color: theme(colors.orange.500) !important;
}

.custom-tab i {
  position: absolute;
  right: 0;
}

.custom-dropdown,
.visible.custom-dropdown {
  padding: 12px !important;
  border: none !important;
  background: none !important;
  box-shadow: none !important;
}

.visible.custom-dropdown {
  padding-bottom: 12px !important;
  /* margin: -5px !important; */
}

.visible.custom-dropdown .menu,
.custom-dropdown .menu {
  margin: 0 !important;
}

.custom-tab-pane {
  border: none !important;
  padding: 0 !important;
  margin: 0 !important;
  box-shadow: none !important;
}

a.active.item {
  color: theme(colors.orange.500) !important;
  font-weight: 600;
  border-color: theme(colors.orange.500) !important;
}

.ui.menu .item.active .ui.dropdown > .default.text,
.ui.menu .item.active .ui.dropdown {
  color: theme(colors.orange.500) !important;
  border-color: theme(colors.orange.500) !important;
}

.ui.menu .item.active .ui.dropdown > .default.text,
.ui.menu .item.active .ui.dropdown {
  max-width: 200px;
}
.ui.menu .item.active.disabled {
  color: theme(colors.orange.300) !important;
  border-color: theme(colors.orange.300) !important;
}

a > .custom-dropdown > div[role='alert'].divider.text {
  font-weight: 600;
}

a.active > .custom-dropdown,
a.active > .custom-dropdown > div[role='listbox'].visible.menu.transition {
  width: calc(100% + 8px);
}

.ui.divider:not(.vertical):not(.horizontal) {
  border-top: 1px solid var(--colors-zinc-100, #f4f4f5) !important;
  border-bottom: 1px solid var(--colors-zinc-100, #f4f4f5) !important;
}

a.active > .custom-dropdown > div[role='alert'].divider.text {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: theme(colors.orange.500) !important;
}
a.active
  > .ui.active.search.dropdown.custom-dropdown
  input.search:focus
  + .text {
  font-weight: 600;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: theme(colors.orange.500) !important;
}

a.active
  > .custom-dropdown
  > div[role='listbox'].visible.menu.transition
  > div[role='option'],
a.active
  > .custom-dropdown
  > div[role='listbox'].visible.menu.transition
  > div[role='option'].active.item,
a.active
  > .custom-dropdown
  > div[role='listbox'].visible.menu.transition
  > div[role='option']:hover {
  display: flex !important;
  height: var(--sizing-12, 48px) !important;
  padding: 0px 25px !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: flex-start !important;
  gap: var(--spacing-25, 10px) !important;
  flex-shrink: 0 !important;
  background-color: transparent !important;
  border: 1px solid var(--border-weak, #f1f5f9) !important;
  color: var(--colors-orange-500, #f97316) !important;
  /* font-family: var(--font-family-body, Lato)!important; */
  font-size: var(--font-size-text-xs, 12px) !important;
  font-style: normal !important;
  font-weight: var(--font-weight-bold, 700) !important;
  line-height: var(--font-leading-text-xs, 16px) !important; /* 133.333% */
}
a.active
  > .custom-dropdown
  > div[role='listbox'].visible.menu.transition
  > div[role='option']:hover,
a.active
  > .custom-dropdown
  > div[role='listbox'].visible.menu.transition
  > div[role='option'].active.item:hover {
  border: 1px solid var(--border-weak, #f1f5f9) !important;
  background: var(--bg-secondary, #f1f5f9) !important;
}

.background-container {
  background-image: url('../assets/images/bg-auth.png');
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
}
.modal-overlay {
  background-color: rgba(255, 255, 255, 0.95);
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  width: 90%;
  max-width: 980px;
  height: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.modal-content {
  display: flex;
  flex-direction: column;
  justify-items: center;
  max-height: 100%;
  overflow-y: auto;
}

.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 35px;
  border: 1px solid var(--colors-orange-500, #f97316) !important;
  color: white;
  border-radius: 100%;
  padding: 10px;
  font-size: 16px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.close {
  position: fixed;
  top: 20px;
  right: 35px;
  border: 1px solid theme(colors.red.500) !important;
  color: theme(colors.red.500) !important;
  background-color: white !important;
  border-radius: 50%;
  padding: 10px;
  font-size: 16px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.close:hover {
  border: 1px solid theme(colors.red.500) !important;
  background-color: theme(colors.red.500) !important;
  color: white !important;
}

.Toastify__toast-body {
  color: #212121 !important;
  text-align: center !important;
  /* font-family: var(--font-family-body, Lato) !important; */
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.Toastify__toast-theme--light {
  color: #b1b1b1 !important;
  text-align: center !important;
  /* font-family: "Font Awesome 6 Pro" !important; */
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.Toastify__progress-bar--success {
  background: #2fc32f !important;
}

.rotate {
  transition: transform 0.3s ease;
}

.rotate.open {
  transform: rotate(180deg);
}

.sendbox {
  display: flex;
  width: 100%;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border: 1px solid var(--colors-gray-200, #e5e7eb);
  margin-right: 10px;
  background-color: white;
}

.sendbox.no-tabs {
  border-radius: 8px;
}

.sendbox.note {
  border: 1px solid var(--colors-amber-100, #fef3c7);
}

.sendbox-tabs {
  display: flex;
  width: 100%;
  padding-left: 12px;
  align-items: flex-start;
  gap: 16px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  align-self: stretch;
  /* background: var(--agidesk-bg-color, #F0F2F5); */
  background: white;
  border: 1px solid var(--colors-gray-200, #e5e7eb);
}

.sendbox-tabs.note {
  border: 1px solid var(--colors-amber-100, #fef3c7);
  background: var(--colors-amber-50, #fffbeb);
}

.sendbox-tabs .ui.secondary.pointing.menu {
  border-bottom-width: 0px;
}
.sendbox-tabs .ui.secondary.pointing.menu .active {
  border-bottom-width: 1px;
}
.sendbox-tabs .item {
  display: flex;
  padding: 6px 0px;
  color: var(
    --agitalks-gray-bench-alphaA0,
    rgba(102, 102, 102, 0.63)
  ) !important;
  text-align: center;
  margin-top: 1px;
  font-size: 12px;
  font-style: normal;
  font-weight: var(--font-weight-medium, 500);
  line-height: 14px; /* 127.273% */
  cursor: pointer;
}
.sendbox-tabs .item.active {
  display: flex;
  padding: 6px 0px;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: var(--font-weight-medium, 500);
  line-height: 14px; /* 127.273% */
  cursor: pointer;
}

.sendbox-textarea,
.sendbox-textarea:disabled {
  display: flex;
  align-items: center;
  align-self: stretch;
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  display: flex;
  width: 100%;
  display: flex;
  flex-shrink: 0;
  font-size: 13px;
  @apply pt-1 bg-white;
  resize: none;
  overflow-y: hidden;
  min-height: 24px;
}

.sendbox-action-container {
  display: flex;
  margin: 0 !important;
  padding: 0 !important;
  align-items: flex-start;
  align-self: stretch;
  width: 100%;
  @apply relative justify-between;
}

.sendbox-action-container .icon {
  @apply text-[20px] w-[24px] h-[24px] border-0;
}
.sendbox-action-container .icon:disabled {
  @apply !text-gray-400;
}

.sendbox-action-container #audio-file {
  height: 24px;
}
.sendbox-action-container #audio-file::-webkit-media-controls-panel,
.sendbox-action-container #audio-file::-webkit-media-controls-play-button,
.sendbox-action-container #audio-file::-webkit-media-controls-volume-slider {
  background-color: white;
  color: theme(colors.gray.700) !important;
}

.inbox-button {
  display: inline-flex;
  height: var(--sizing-8, 32px);
  padding: 0px var(--spacing-4, 16px);
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  align-items: center;
  border-radius: var(--rounded-2, 8px);
  border: 1px solid var(--agitalks-gray-ccc, #ccc);
  gap: var(--spacing-1, 4px);
  color: var(--agitalks-gray-777, #777);
  font-size: var(--font-size-text-xs, 12px);
  font-style: normal;
  font-weight: 600;
  line-height: var(--font-leading-text-xs, 16px); /* 133.333% */
}

.filter-tags {
  display: flex;
  height: 24px;
  padding: 0px var(--spacing-3, 12px);
  justify-content: center;
  align-items: center;
  gap: var(--spacing-1, 4px);
  flex-shrink: 0;
  border-radius: 999px;
  border: 1px solid var(--agitalks-gray-ccc, #ccc);
}

.filter-tags span {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: var(--agitalks-gray-777, #777);
  text-align: center;
  text-overflow: ellipsis;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 145.455% */
}

.filter-tags:hover,
.filter-tags:hover span,
.filter-tags span:hover {
  cursor: pointer;
  background: var(--colors-slate-50, #f8fafc);
  color: var(--agitalks-agidesk-default, #f2711c);
}

.filter-tags.disabled,
.filter-tags.disabled span {
  color: var(--agitalks-gray-ccc, #ccc) !important;
  background: none !important;
  cursor: auto !important;
}
.filter-tags.selected.disabled,
.filter-tags.selected.disabled span {
  background: var(--colors-orange-50, #fff7ed) !important;
  color: var(--agitalks-agidesk-alphaA0, rgba(242, 113, 28, 0.63)) !important;
  cursor: auto !important;
}

.filter-tags.selected {
  border: 1px solid var(--colors-orange-300, #fdba74);
  background: var(--colors-orange-50, #fff7ed);
}

.filter-tags.selected span {
  color: var(--colors-orange-500, #f97316);
}

.filter-tags.selected:hover,
.filter-tags.selected:hover span,
.filter-tags.selected span:hover {
  cursor: pointer;
  background: var(--colors-orange-200, #fed7aa);
  color: var(--agitalks-agidesk-default, #f2711c);
}

.chat-sort {
  display: flex;
  align-items: center;
  width: 82px;
  height: 24px;
  gap: 2px;
  flex-shrink: 0;
  color: var(--agitalks-blue-link, #4183c4);
  text-align: center;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 11px; /* 100% */
}

.chat-sort.disabled {
  color: var(--agitalks-gray-ccc, #ccc) !important;
  cursor: auto !important;
}

.chat-sort:hover {
  cursor: pointer;
  color: var(--agitalks-blue-link--hover, #1e70bf);
}

.disclaimer-offline {
  display: flex;
  margin-bottom: 12px;
  padding: var(--spacing-3, 12px) var(--spacing-4, 16px);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: var(--rounded-1, 4px);
  border: 1px solid var(--colors-yellow-500, #eab308);
}

.disclaimer-container {
  display: flex;
  align-items: center;
  gap: var(--spacing-4, 16px);
}

.disclaimer-icon {
  display: flex;
  width: 20px;
  height: 20px;
  font-size: 20px;
  justify-content: center;
  align-items: center;
  color: var(--colors-yellow-500, #eab308);
}

.disclaimer-content {
  display: flex;
  padding-top: 2px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
}

.disclaimer-content > span {
  color: var(--agitalks-gray-333, #333);
  /* font-family: Lato; */
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 14.4px */
}

.disclaimer-content > p {
  color: var(--agitalks-gray-333, #333);
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 18px */
}

/* ==================  ======================== */

@layer utilities {
  .leading-trim-both {
    leading-trim: both;
  }
  .text-edge-cap {
    text-edge: cap;
  }
  .line-clamp-1 {
    @apply overflow-hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }
}

#WhatsAppMobilePreview {
  display: flex;
  width: 295px;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 40px;
  border: 16px solid theme(colors.slate.900);
  padding: 0;
  box-shadow: 18px 41px 45px 0px rgba(67, 20, 7, 0.18),
    4px 10px 24px 0px rgba(67, 20, 7, 0.2);
}

#BusinessDetails {
  display: flex;
  padding: 28px theme(spacing.5) 20px theme(spacing.5);
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-radius: theme(spacing.5) theme(spacing.5) 0px 0px;
  background: var(--color-whastapp-base, #085b53);
}

#WhatsAppBody {
  display: flex;
  height: 430px;
  overflow-y: auto;
  padding: theme(spacing.5);
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 0px 0px theme(spacing.5) theme(spacing.5);
  background-image: url('../assets/images/whats-body.png');
}

#WhatsAppBody::-webkit-scrollbar,
#content::-webkit-scrollbar {
  width: 10px !important;
}

#WhatsAppBody::-webkit-scrollbar-track,
#content::-webkit-scrollbar-track {
  background: transparent !important;
}

#WhatsAppBody::-webkit-scrollbar-thumb,
#content::-webkit-scrollbar-thumb {
  background: transparent !important;
  border-radius: 4px !important;
}

#WhatsAppBody::-webkit-scrollbar-thumb:hover,
#content::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.1) !important;
}

#WhatsAppBodyContent {
  /* Shadow/default */
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1),
    0px 1px 2px -1px rgba(0, 0, 0, 0.1);
}

#island {
  width: 78px;
  height: 12px;
  position: absolute;
  left: 50%;
  top: 6px;
  transform: translateX(-50%);
  border-radius: 999px;
  background: theme(colors.slate.900);
}

.icon-box {
  display: flex;
  min-width: 48px;
  max-width: 48px;
  min-height: 48px;
  max-height: 48px;
  padding: var(--spacing-35, 14px);
  justify-content: center;
  align-items: center;
  gap: var(--spacing-1, 4px);
  border-radius: var(--rounded-3, 12px);
}

#dropdown-externalapptype,
#dropdown-externalapptype.ui.dropdown,
#dropdown-externalapptype.ui.dropdown .menu {
  min-width: 115px;
  @apply rounded-r-none;
}
#dropdown-externalappnumber,
#dropdown-externalappnumber.ui.dropdown,
#dropdown-externalappnumber.ui.dropdown .menu {
  min-width: 115px;
  @apply rounded-l-none;
}

#dropdown-externalappnumber,
#dropdown-externalappnumber.ui.dropdown {
  @apply border-l-0;
}
#dropdown-externalapptype.ui.dropdown .menu .item {
  cursor: pointer;
}
#dropdown-externalapptype.ui.dropdown .menu .item:hover {
  @apply text-orange-600;
}
#dropdown-externalapptype.ui.dropdown .menu .item.active,
#dropdown-externalapptype.ui.dropdown .active.selected.item > .text {
  @apply text-orange-500;
}

/* Variantes Solid */
#badge.critical.solid {
  @apply text-base-white bg-red-500;
}

#badge.primary.solid {
  @apply text-base-white bg-orange-500;
}

#badge.neutral.solid {
  @apply text-base-white bg-slate-500;
}

#badge.success.solid {
  @apply text-base-white bg-emerald-600;
}

#badge.warning.solid {
  @apply text-base-white bg-amber-600;
}

#badge.info.solid {
  @apply text-base-white bg-blue-500;
}

#badge.violet.solid {
  @apply text-base-white bg-violet-500;
}

/* Variantes Soft */
#badge.critical.soft {
  @apply text-red-800 bg-red-100;
}
#badge.critical.soft > i {
  @apply text-red-500;
}

#badge.primary.soft {
  @apply text-orange-500 bg-orange-100;
}

#badge.neutral.soft {
  @apply text-slate-600 bg-slate-200;
}

#badge.success.soft {
  @apply text-emerald-800 bg-emerald-100;
}
#badge.success.soft > i {
  @apply text-emerald-600;
}

#badge.warning.soft {
  @apply text-amber-800 bg-amber-100;
}
#badge.warning.soft > i {
  @apply text-amber-500;
}

#badge.info.soft {
  @apply text-blue-800 bg-blue-100;
}
#badge.info.soft > i {
  @apply text-blue-600;
}

#badge.violet.soft {
  @apply text-violet-800 bg-violet-100;
}
#badge.violet.soft > i {
  @apply text-violet-600;
}

/* Variantes Solid Disabled */
#badge.critical.solid.disabled {
  @apply text-base-white bg-red-300;
}

#badge.primary.solid.disabled {
  @apply text-base-white bg-orange-200;
}

#badge.neutral.solid.disabled {
  @apply text-base-white bg-slate-300;
}

#badge.success.solid.disabled {
  @apply text-base-white bg-[#10B98180];
}

#badge.warning.solid.disabled {
  @apply text-base-white bg-[#D9770680];
}

#badge.info.solid.disabled {
  @apply text-base-white bg-blue-300;
}

#badge.violet.solid.disabled {
  @apply text-base-white bg-violet-300;
}

/* Variantes Soft Disabled */
#badge.critical.soft.disabled {
  @apply text-red-300 bg-red-100;
}

#badge.primary.soft.disabled {
  @apply text-orange-300 bg-orange-100;
}

#badge.neutral.soft.disabled {
  @apply text-slate-400 bg-slate-200;
}

#badge.success.soft.disabled {
  @apply text-emerald-300 bg-emerald-100;
}

#badge.warning.soft.disabled {
  @apply text-amber-400 bg-amber-100;
}

#badge.info.soft.disabled {
  @apply text-blue-300 bg-blue-100;
}

#badge.violet.soft.disabled {
  @apply text-violet-300 bg-violet-100;
}

#dropdown-button-type .divider.default.text {
  @apply !text-slate-700;
}

#ButtonFormGroup .fields > .field {
  @apply flex flex-col gap-2.5;
}

@media (min-width: 1537px) {
  .container {
    max-width: 1365px !important;
  }
}

@media (min-width: 1440px) {
  .container {
    max-width: 1365px !important;
  }
}

.ui[class*='very basic'].table:not(.sortable):not(.striped) th {
  @apply pb-0;
}
